/* RSSFeedWindow.css */

.rss-feed-window {
    position: absolute;
    top: 50px; /* Adjust as needed */
    left: 50px; /* Adjust as needed */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .rss-feed-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
/* Styles for individual feed items */
  .rss-feed-item {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    transition: background-color 0.3s ease;
  }
  
  .rss-feed-item:hover {
    background-color: #f5f5f5;
  }
  
  .rss-feed-item-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .rss-feed-item-description {
    color: #666;
    font-size: 14px;
  }
  /* Add any other necessary styles for the RSS feed window */
  
/* AboutPopup.css */
.about-popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about-popup {
    font-family: 'Aldrich', sans-serif;
    color: white;
    background-color: rgba(0, 127, 255, 0.7); /* Semi-transparent background overlay */
    border-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    z-index: 9999; /* Adjust this value as needed */
  }

  .about-button button {
    font-family: 'Aldrich', sans-serif;
    background-color: #ff7700; /* Gray background color */
    color: #333; /* Dark text color */
    border: 1;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 5px; /* Add spacing between buttons */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }